import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingSpinner = ({ className }: { className?: string }) => {
	return (
		<div className="w-full h-full flex flex-row content-center justify-center items-center bg-black">
			<CircularProgress size={24} className={className} color="primary" />
		</div>
	);
};

export default LoadingSpinner;
